<template>
  <div>
    <page-header class="mb-3" :title="$t('HomepageTitle')" :message="$t('HomepageMessage')" />
    <b-row class="d-flex justify-content-center mb-2">
      <b-col md="4">
        <b-card
          no-body
          :img-src="require('@/assets/images/pages/home/Meet_the_Mentors_FBW.jpg')"
          img-alt="Card image ice-cream"
          img-top
        >
          <b-card-body>
            <b-card-title>
              {{ $t('Our mentors') }}
            </b-card-title>
            <b-card-text>
              {{ $t('Our mentors subtext') }}
            </b-card-text>
            <b-button
              variant="outline-primary"
              :to="{name: 'our-mentors'}"
            >
              {{ $t('Meet them') }}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          no-body
          :img-src="require('@/assets/images/pages/home/Choose_Programme_F.jpg')"
          img-alt="Card image stool"
          img-top
        >
          <b-card-body>
            <b-card-title>
              {{ $t('MentoringPackages') }}
            </b-card-title>
            <b-card-text>
              {{ $t('MentoringPackages subtext') }}
            </b-card-text>
            <b-button
              variant="outline-primary"
              :to="{name: 'mentoring-packages'}"
            >
              {{ $t('Which package') }}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <b-col md="5" class="d-flex flex-column align-items-center text-center">
        <h4 class="mb-1">
          {{ $t('You do not know what mentoring is?') }}
        </h4>
        <b-button variant="outline-primary" :to="{ name: 'pages-knowledge-base-category', params: { category: 'mentoring-in-general' } }">
          {{ $t('Learn more') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BRow,
} from 'bootstrap-vue'
import PageHeader from "@mentoring-platform/views/components/blocks/PageHeader.vue"

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BCol,
    BRow,
    PageHeader
  },
  data() {
    return {

    }
  }
};
</script>

<style>
</style>
